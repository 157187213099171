.pay-header {
    position: relative;
    top: 0rem;
    width: 100%;
    height: 30vw;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    background-color: #FF754A;
    text-align: center;
    line-height: 16vw;
    color: #FFE4E1;
    font-size: 4vw;
    z-index: 1;
}

.pay-content {
    position: relative;
    background-color: #FFEFE9;
    height: 100%;
    width: 100%;
    margin-top: -10vw;
    padding-bottom: 12%;
}

.pay-goods {
    width: 92%;
    background-color: #fff;
    margin: auto;
    position: relative;
    top: -4vw;
    z-index: 10;
    border-radius: 2vw;
    box-shadow: 0.5rem 0.5rem 0.5rem #ff00001c;
}

.pay-goods-title-ico {
    height: 5vw;
    width: 2vw;
    background-color: #FFC61C;
    position: absolute;
    border-radius: 2vw;
    left: 0vw;
    top: -4vw;
}

.pay-goods-title-name {
    position: absolute;
    left: 4vw;
    font-size: 4vw;
    color: #000000;
    top: -4.4vw;
}

.pay-goods-items-content {
    width: 97%;
    height: 35vw;
    margin: auto;
    position: absolute;
    left: 1vw;
    border-bottom: 0.3vw solid #F7F7F7;
    top: 2vw;
}

.pay-goods-title {
    height: 6vw;
}

.pay-goods-items {
    position: relative;
    width: 86%;
    height: 37vw;
    top: 12vw;
    margin: auto;
}

.pay-goods-items-img {
    width: 22vw;
    height: 22vw;
    background-color: deepskyblue;
    float: left;
    position: absolute;
    top: 5vw;
    left: 2vw;
    border-radius: 1vw;
}

.pay-goods-items-img > img {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
}


.pay-goods-items-name {
    position: absolute;
    top: 6vw;
    left: 27vw;
    font-size: 4vw;
    color: #000000;
}

.pay-goods-items-no {
    position: absolute;
    top: 6.7vw;
    right: 0vw;
    background-color: #f5f5f5;
    color: #7f7f7f;
    font-size: 3.5vw;
    width: 25%;
    text-align: center;
    border-radius: 1vw;
}

.pay-goods-items-floor {
    position: absolute;
    top: 13vw;
    left: 27vw;
    color: #B2B2B2;
    font-size: 3vw;
}

.pay-goods-items-mobile-img {
    position: absolute;
    top: 21vw;
    left: 27vw;
    width: 4vw;
    height: 4vw;
}

.pay-goods-items-mobile-img > img {
    width: 100%;
    height: 100%;
}

.pay-goods-items-mobile-number {
    position: absolute;
    top: 21vw;
    left: 32vw;
    font-size: 4vw;
    color: #000000;
}

.pay-phone {
    position: relative;
    top: 38vw;
    width: 88%;
    height: 40vw;
    margin: auto;
}

.pay-phone-ico {
    height: 5vw;
    width: 2vw;
    background-color: #FFC61C;
    position: absolute;
    border-radius: 2vw;
    left: 1vw;
    top: -19vw;
}

.pay-phone-title {
    position: absolute;
    font-size: 4vw;
    color: #000000;
}

.pay-phone-name {
    position: absolute;
    width: 14vw;
    left: 5vw;
    top: -19.2vw;
}

.pay-phone > input {
    position: absolute;
    background-color: #F5F5F5;
    color: #000;
    top: -9vw;
    left: 3vw;
    width: 88%;
    height: 10vw;
    border-radius: 1vw;
    font-size: 5vw;
    padding-left: 4vw;
    border: 0;
}

.pay-phone > input:focus{
    border-radius: 1vw;
    background-color: #F5F5F5;
    outline:none;
}

.pay-money {
    position: relative;
    font-size: 4vw;
    color: #000000;
    top: 4vw;
    left: 9vw;
    width: 12%;
}
.pay-money-input {
    padding-bottom: 26vw;
    width: 82%;
    margin: auto;
    top: -7.5vw;
    position: relative;
    border-bottom: 0.3vw solid #E6E6E6;
}

.pay-money-input-mo {
    position: absolute;
    font-size: 8vw;
    color: #000000;
    top: 12.5vw;
}

.pay-money-input-mo .money-flag {
    width: 8vw;
    height: 8vw;
    position: absolute;
    top: 1.5vw;
    z-index: 1;
}

.pay-money-input-mo > input {
    color: #000;
    width: 62vw;
    height: 8vw;
    border-radius: 1vw;
    font-size: 9vw;
    border: 0;
    outline: none;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    position: absolute;
    left: 7vw;
    top: 0.5vw;
    padding-left: 2vw;
}

.footer {
    position: relative;
    width: 100%;
    height: 32vw;
    text-align: center;
    margin-top: 6vw;
}

.pay-sub {
    color: rgb(255, 254, 253);
    background-color: #FF754A;
    font-size: 4vw;
    font-weight: 700;
    border-radius: 8vw;
    border: 0;
    line-height: 10vw;
    width: 82%;
    outline: none;
}

.view-order {
    font-size: 3vw;
    width: 12%;
    margin: 6vw auto;
    height: 5vw;
    border-bottom: 0.1vw solid #FF5141;
}

.view-order > a {
    color: #FF5141;
}