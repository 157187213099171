  #modal-root {
    position: relative;
    z-index: 999;
  }
  
  .app {
    height: 10em;
    width: 10em;
    background: lightblue;
    overflow: hidden;
  }
  
  .modal {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .modal-size {
    position: relative;
    top: -22vw;
    border-radius: 2vw;
    margin: auto;
  }
  
  .modal-head {
    height: 13vw;
    width: 80vw;
    background: #fff;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
  }

  .modal-head .title {
    position: absolute;
    top: 4vw;
    left: 3vw;
    font-size: 4vw;
  }

  .x-close {
    position: absolute;
    top: 4vw;
    right: 3vw;
    background: #fff;
    border-style: none;
    font-size: 4vw;
  }

  .modal-content {
    position: absolute;
    top: 12vw;
    width: 80vw;
    height: 41vw;
    background: #fff;
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;
    border-top: 0.1rem solid #ccc;
  }

  .item-content {
    height: 12vw;
    font-size: 4vw;
    margin-top: 4vw;
    margin-left: 3vw;
  }

  .item-content input {
    height: 7vw;
    width: 49vw;
    border-radius: 1vw;
    font-size: 4vw;
    padding-left: 2vw;
    line-height: 4vw;
  }

  .item-content .submit {
    height: 9vw;
    width: 19vw;
    float: right;
    margin-right: 9vw;
    font-size: 4vw;
    text-align: center;
    line-height: 4vw;
    border-radius: 1vw;
    border-style: none;
    background: #4e6ef2;
    color: #fff;
  }