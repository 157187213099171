.radio-wrap {
    height: 40px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0px 30px;

    &:active {
        background-color: rgb(221, 221, 221);
    }

    .left {
        display: inline-block;

        .circle {
            display: inline-block;
            height: 22px;
            width: 22px;
            box-sizing: border-box;
            border: 1px solid #c5c9cd;
            border-radius: 50%;
            background-color: #ffffff;
            position: relative;

            
        }
        .active{
            background-color: #1eb94a;
            .fork {
                height: 12px;
                width: 5px;
                border-right: 1.5px solid #ffffff;
                border-bottom: 1.5px solid #ffffff;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        .label {
            vertical-align: top;
            margin-left: 10px;
            display: inline-block;
            height: 22px;
            line-height: 22px;
            font-size: 14px;
        }
    }

}
