.weixin-tip {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    // filter: alpha(opacity = 80);
    opacity: 1;
    height: 100%;
    width: 100%;
    z-index: 100;
    
    .tips {
        text-align: center;
        margin-top: 40vw;
        padding: 0 5%;
        color: #fff;
        font-size: 5vw;
    }
    img {
        width: 40vw;
        height: 40vw;
        position: absolute;
        top: 0;
        right: 0;
        // -moz-opacity: 0.4;
        // -khtml-opacity: 0.5;
        // opacity: 0.5;
    }
}