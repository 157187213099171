.pay-header {
    position: relative;
    top: 0rem;
    width: 100%;
    height: 30vw;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    background-color: #FF754A;
    text-align: center;
    line-height: 16vw;
    color: #FFE4E1;
    font-size: 4vw;
    z-index: 1;
}

.go-back {
    position: absolute;
    width: 7vw;
    height: 5vw;
    top: 1vw;
    left: 0;
}

.go-back > img {
    width: 100%;
    height: 100%;
}

.pay-content {
    position: relative;
    background-color: #FFEFE9;
    height: 100%;
    width: 100%;
    margin-top: -10vw;
    padding-bottom: 12%;
}

.pay-goods {
    width: 92%;
    background-color: #fff;
    margin: auto;
    position: relative;
    top: -4vw;
    z-index: 10;
    border-radius: 2vw;
    box-shadow: 0.5rem 0.5rem 0.5rem #ff00001c;
}

.pay-goods-title-ico {
    height: 5vw;
    width: 2vw;
    background-color: #FFC61C;
    position: absolute;
    border-radius: 2vw;
    left: 0vw;
    top: -4vw;
}

.pay-goods-title-name {
    position: absolute;
    left: 4vw;
    font-size: 4.4vw;
    color: #000000;
    top: -4vw;
}

.pay-goods-order-items-content {
    width: 97%;
    height: 53vw;
    margin: auto;
    position: absolute;
    left: 1vw;
    border-bottom: 0.3vw solid #F7F7F7;
    top: 2vw;
}

.pay-goods-title {
    height: 6vw;
}

.pay-goods-order-items {
    position: relative;
    width: 86%;
    height: 68vw;
    top: 12vw;
    margin: auto;
}

.pay-goods-order-items-img {
    width: 22vw;
    height: 22vw;
    background-color: deepskyblue;
    float: left;
    position: absolute;
    top: 5vw;
    left: 2vw;
    border-radius: 1vw;
}

.pay-goods-order-items-img > img {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
}

.pay-goods-order-items-name {
    position: absolute;
    top: 6vw;
    left: 27vw;
    font-size: 4vw;
    color: #000000;
}

.pay-goods-order-items-no {
    position: absolute;
    top: 6.7vw;
    right: 0vw;
    background-color: #f5f5f5;
    color: #7f7f7f;
    font-size: 3.5vw;
    width: 25%;
    text-align: center;
    border-radius: 1vw;
}

.pay-goods-order-items-floor {
    position: absolute;
    top: 13vw;
    left: 27vw;
    color: #B2B2B2;
    font-size: 3vw;
}

.pay-goods-order-items-mobile-img {
    position: absolute;
    top: 21vw;
    left: 27vw;
    width: 4vw;
    height: 4vw;
}

.pay-goods-order-items-mobile-img > img {
    width: 100%;
    height: 100%;
}

.pay-goods-order-items-mobile-number {
    position: absolute;
    top: 21vw;
    left: 32vw;
    font-size: 4vw;
    color: #000000;
}

.pay-goods-order-items-money {
    position: absolute;
    top: 21vw;
    right: 0vw;
    font-size: 4vw;
    color: #000000;
}

.pay-goods-title-state {
    position: absolute;
    right: 0vw;
    font-size: 4vw;
    color: #FF754A;
    top: -4.4vw;
}

.pay-number-time {
    position: relative;
    top: 27vw;
    color: #000000;
    font-size: 3.5vw;
}

.pay-number {
    position: absolute;
    left: 4vw;
}

.pay-time {
    position: absolute;
    left: 4vw;
    top: 6vw;
}

.pay-invoice {
    position: absolute;
    left: 4vw;
    top: 12vw;
}